<template>
  <div class="ApprovalTag">
    <!-- 待审批指标 -->
    <van-nav-bar
      :left-text="$t('ApprovalTaget.apptxt1')"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      @click-right="showPicker = true"
    >
      <template #right>
        {{ khtime.khtimename }} <van-icon name="arrow-down" size="18" />
      </template>
    </van-nav-bar>
    <div class="content">
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeAct"
      >
        <van-tab
          v-if="$t('ApprovalTaget.apptxt2') && khtime.is360"
          :title="$t('ApprovalTaget.apptxt2')"
          name="360Tag"
        ></van-tab>
        <van-tab
          v-if="$t('ApprovalTaget.apptxt3') && khtime.iskpi"
          :title="$t('ApprovalTaget.apptxt3')"
          name="KPITag"
        ></van-tab>
      </van-tabs>
      <van-pull-refresh
        :class="activeName == 'KPITag' ? 'KPITagRefresh' : ''"
        v-model="isLoadingAwait"
        @refresh="onRefreshAwait"
      >
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <van-collapse
            v-model="activeNames1"
            class="first_coll"
            @change="collapseChange"
          >
            <van-collapse-item
              :title="item.title"
              :name="item.autoid"
              v-for="(item, index) in assessList"
              :key="index"
            >
              <template #value>
                <div>
                  <van-button
                    plain
                    type="primary"
                    size="mini"
                    @click="agreeItem(item)"
                    >同意</van-button
                  >
                  <van-button
                    plain
                    type="danger"
                    size="mini"
                    @click="unAgreeItem(item)"
                    >不同意</van-button
                  >
                </div>
              </template>
              <div
                class="zb_group"
                v-for="(it, i) in item.ch"
                :key="i"
                @click="goToDetails(it)"
              >
                <van-cell-group>
                  <van-cell
                    class="top_cell"
                    center
                    :title="
                      activeName == '360Tag' ? it.indicatorname : it.itemname
                    "
                    :value="activeName == '360Tag' ? it.typename : it.itemtype"
                  >
                  <template #right-icon>
                  <van-icon
                    :name="deleteAss"
                    style="margin-left: 10px"
                    @click.stop="deleteTag(it)"
                  />
                </template>
                  </van-cell>
                  <van-cell class="btm_cell" center>
                    <template #title>
                      <span
                        v-if="activeName == '360Tag'"
                        class="custom-title"
                        >{{ it.indicatordesp }}</span
                      >
                      <span v-else class="custom-title">{{ it.itemdesc }}</span>
                    </template>
                    <template #label>
                      <span
                        v-if="activeName == '360Tag'"
                        class="custom-score"
                        >{{ it.score }}</span
                      >
                      <span v-else class="custom-score">{{ it.tgvalue }}</span>
                      {{ $t('jxApprovalTagApply.atTxt1') }}
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
              <van-button type="warning" @click="add(item)">添加</van-button>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- <div class="footer" v-if="assessList.length > 0">
      <div class="f_left" @click="unAgreeItem">
        {{ $t('jxApprovalTagApply.atTxt2') }}
      </div>
      <div class="f_right" @click="agreeItem">
        {{ $t('jxApprovalTagApply.atTxt3') }}
      </div>
    </div> -->
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="'请选择考核周期'"
        show-toolbar
        :columns="
          khTimeList.map(e => {
            return e.khtimename
          })
        "
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-dialog
      v-model="showDia"
      :title="$t('jxApprovalTagApply.atTxt2')"
      show-cancel-button
      :before-close="closeDia"
    >
      <div style="padding: 10px">
        <van-cell class="desc_cell" :value="$t('jxApprovalTagApply.atTxt4')" />
        <van-field
          class="desc_field"
          v-model="checkmemo"
          rows="3"
          autosize
          type="textarea"
          :placeholder="$t('jxApprovalTagApply.atTxt4')"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  getKhTimeList,
  getMy360ForCheckList_ForKhTime,
  getMyKpiForCheckList_ForKhTime,
  agreeMy360KhIndicator,
  agreeMyKpiKhIndicator,
  // getMy360ForCheckList,
  // getMyKpiForCheckList
  get360ForCheckEmpList,
  getMy360ForCheckList_GroupDetail,
  getKPIForCheckEmpList,
  getMyKPIForCheckList_GroupDetail,
  deleteMy360Indicator,
  deleteMyKpiIndicator,
} from '@api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      deleteAss: require('@/assets/img/deleteAss.png'),
      activeName: '360Tag',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      searchVal: '',
      showPicker: false,
      khTimeList: [],
      khtime: { autid: 0 },
      checkmemo: '', // 不同意的原因
      showDia: false,
      BaseKPI: {},
      Base360: {},
      activeNames1: [],
      unAgeItemE: {}
    }
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    let is360 = true
    if (
      from.name == 'jxApprovalTagApply' ||
      from.name == 'jxMyAssTargetDetails' ||
      from.name == 'jxApprovalTagDetails'
    ) {
      is360 = from.query.is360
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (is360 == true || is360 == 'true') {
        vm.activeName = '360Tag'
      } else {
        vm.activeName = 'KPITag'
      }
    })
  },
  methods: {
    onConfirm(value, index) {
      this.setKhtime(this.khTimeList[index])
    },
    setKhtime(n) {
      this.khtime = n
      if (
        (this.activeName == 'GSTag' && n.isgs) ||
        (this.activeName == 'KPITag' && n.iskpi) ||
        (this.activeName == '360Tag' && n.is360)
      ) {
      } else {
        this.activeName = n.is360 ? '360Tag' : n.iskpi ? 'KPITag' : 'GSTag'
      }
      this.showPicker = false
      this.onLoadAwait()
      Toast(this.$t('module.refreshSuccessfully'))
    },
    _getKhTimeList() {
      getKhTimeList({
        username: this.userInfo.username,
        flag: 0
      }).then(async res => {
        this.khTimeList = res.data
        if (res.data.length > 0) {
          if (this.$route.query.isGS == '1') {
            this.activeName = 'GSTag'
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              if (element.autoid == this.$route.query.khtimeid) {
                this.setKhtime(element)
              }
            }
          } else {
            this.setKhtime(res.data[0])
          }
        }
        this.onLoadAwait()
        Toast(this.$t('module.refreshSuccessfully'))
      })
    },
    onSearch() {
      if (this.searchVal) {
        this.assessList = this.assessList.filter(
          item =>
            item.khtimename.indexOf(this.searchVal) > -1 ||
            item.planname.indexOf(this.searchVal) > -1 ||
            item.empname.indexOf(this.searchVal) > -1
        )
      } else {
        this.getMyKpiList()
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    /// 9.1.获取待审批的360考核方案列表
    getMy360List() {
      let _this = this
      this.assessList = []
      getMy360ForCheckList_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        this.Base360 =
          res.baseinfo.length > 0
            ? res.baseinfo[0]
            : {
                mautoid: 0,
                noreqcheckreason: null
              }
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 9.1.获取待审批的360考核方案列表
    getMyKpiList() {
      let _this = this
      this.assessList = []
      getMyKpiForCheckList_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        this.BaseKPI =
          res.baseinfo.length > 0
            ? res.baseinfo[0]
            : {
                is_jjp: false,
                is_zjp: false,
                lastscore: 0,
                mautoid: 0
              }
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    onLoadAwait() {
      if (this.khtime.autid == 0) {
        this._getKhTimeList()
        return
      }
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      if (this.activeName == '360Tag') {
        // this.getMy360List()
        this._get360ForCheckEmpList()
      } else {
        // this.getMyKpiList()
        this._getKPIForCheckEmpList()
      }
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply(item) {
      let is360 = true
      const mautoid = item.autoid
      if (this.activeName == '360Tag') {
        is360 = true
      } else {
        is360 = false
      }
      this.$router.push({
        path: '/jxApprovalTagApply',
        query: {
          title: item.khtimename,
          is360: is360,
          mautoid: mautoid,
          lastscore: item.lastscore,
          is_jjp: item.is_jjp ? item.is_jjp : '',
          is_zjp: item.is_zjp ? item.is_zjp : ''
        }
      })
    },
    goToDetails(item) {
      let is360 = true,
        obj = {}
      const detaId = item.autoid
      if (this.activeName == '360Tag') {
        is360 = true
        obj = this.Base360
      } else {
        is360 = false
        obj = this.BaseKPI
      }
      this.$router.push({
        path: '/jxApprovalTagDetails',
        query: {
          parentTitle: this.title,
          is360: is360,
          mautoid: obj.mautoid,
          detaId: detaId,
          lastscore: obj.lastscore,
          is_zjp: obj.is_zjp, // 是否显示直接评价人
          is_jjp: obj.is_jjp, // 是否显示间接评价人
          readonly: 1,
          from: 'jxApprovalTag',
          khtimeid: this.khtime.autoid
        }
      })
    },
    // 同意按钮
    agreeItem(e) {
      this.unAgeItemE = e
      if (this.assessList.length == 0) {
        Toast.fail(this.$t('jxApprovalTagApply.atTxt6'))
        return false
      }
      Dialog.confirm({
        title: this.$t('jxApprovalTagApply.atTxt3'),
        message: this.$t('jxApprovalTagApply.atTxt7')
      })
        .then(() => {
          if (this.activeName == '360Tag') {
            this.agreeMy360(1, '')
          } else {
            this.agreeMyKpi(1, '')
          }
        })
        .catch(() => {})
    },
    // 不同意按钮
    unAgreeItem(e) {
      this.unAgeItemE = e
      // this.$router.push('/jxMy360TargetDetails')
      if (this.assessList.length == 0) {
        Toast.fail(this.$t('jxApprovalTagApply.atTxt6'))
        return false
      }
      this.showDia = true
    },

    // 弹窗关闭回调
    closeDia(action, done) {
      if (action == 'confirm') {
        if (this.checkmemo) {
          if (this.activeName == '360Tag') {
            this.agreeMy360(0, this.checkmemo)
          } else {
            this.agreeMyKpi(0, this.checkmemo)
          }
          done()
        } else {
          Toast.fail(this.$t('jxApprovalTagApply.atTxt8'))
          done(false)
        }
      } else {
        done()
      }
    },
    // 360同意/不同意
    agreeMy360(ischeck, checkmemo) {
      agreeMy360KhIndicator({
        mautoid: this.unAgeItemE.mautoid,
        ischeck: ischeck,
        checkmemo: checkmemo,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror == 0) {
          Toast.success(this.$t('jxApprovalTagApply.atTxt5'))
          this.onRefreshAwait()
        }
      })
    },
    // KPI同意/不同意
    agreeMyKpi(ischeck, checkmemo) {
      agreeMyKpiKhIndicator({
        mautoid: this.unAgeItemE.mautoid,
        ischeck: ischeck,
        checkmemo: checkmemo,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror == 0) {
          Toast.success(this.$t('jxApprovalTagApply.atTxt5'))
          this.onRefreshAwait()
        }
      })
    },
    _get360ForCheckEmpList() {
      let _this = this
      this.assessList = []
      get360ForCheckEmpList({
        username: this.userInfo.username,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.assessList.forEach(e => {
            _this._getMy360ForCheckList_GroupDetail(e)
          })
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    _getKPIForCheckEmpList() {
      let _this = this
      this.assessList = []
      getKPIForCheckEmpList({
        username: this.userInfo.username,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.assessList.forEach(e => {
            _this._getMyKPIForCheckList_GroupDetail(e)
          })
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },

    _getMy360ForCheckList_GroupDetail(e) {
      let _this = this
      getMy360ForCheckList_GroupDetail({
        username: this.userInfo.username,
        khtimeid: this.khtime.autoid,
        empid: e.empid,
        mautoid: e.mautoid
      }).then(res => {
        if (res.data.length == 0) {
        } else {
          e.ch = res.data
        }
      })
    },
    _getMyKPIForCheckList_GroupDetail(e) {
      let _this = this
      getMyKPIForCheckList_GroupDetail({
        username: this.userInfo.username,
        khtimeid: this.khtime.autoid,
        empid: e.empid,
        mautoid: e.mautoid
      }).then(res => {
        if (res.data.length == 0) {
        } else {
          e.ch = res.data
         
        }
      })
    },
    add(e){
      // console.log(this.title, 'this.title')
      const length = e.ch.length || 0
      let title = this.$t('jxMyAssTargetDetails.txt16'),
        is360 = true
      if (this.activeName == '360Tag') {
        title = this.$t('jxMyAssTargetDetails.txt16')
        is360 = true
      } else {
        title = this.$t('jxMyAssTargetDetails.txt17')
        is360 = false
      }

      this.$router.push({
        path: '/jxMyAssTargetDetails',
        query: {
          parentTitle: title,
          is360: is360,
          mautoid: e.mautoid,
          checkflag: e.checkflag,
          detaId: '',
          length: length,
          usejj_forid: e.usejj_forid,
          usezj_forid: e.usezj_forid,
          isadd: 1,
          lastscore: this.lastscore,
          req_indicator_select: e.req_indicator_select,
          khtimeid: this.khtime.autoid,
          from:'jxApprovalTag'
        }
      })
    },
    // 删除按钮
    deleteTag(item) {
      console.log(item, 'itemitem')
      if (this.activeName == '360Tag') {
        // 删除360
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMy360Indicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0) {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => {})
      } else {
        // 删除KPI
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMyKpiIndicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0) {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => {})
      }
    },
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      color: #fff;
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
      .first_coll {
        padding: 20px 30px;
        /deep/ .van-collapse-item {
          border-radius: 12px 12px 0 0;
          margin-bottom: 30px;
          .van-cell::after {
            border-bottom: unset;
          }
          .van-collapse-item__title {
            padding: 10px 15px;
            text-align: center;
            border-radius: 12px 12px 0 0;
            background: #2b8df0;
            .van-cell__title {
              color: #fff;
              font-weight: bold;
              font-size: 26px;
              font-family: Source Han Sans CN;
              flex: 2;
            }
            .van-icon {
              color: #fff;
            }
          }
        }
      }
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    // .zb_group {
    //   // .van-cell::after {
    //   //   position: absolute;
    //   //   box-sizing: border-box;
    //   //   content: " ";
    //   //   pointer-events: none;
    //   //   right: 0.42667rem;
    //   //   bottom: 0;
    //   //   left: 0.42667rem;
    //   //   border-bottom: 2px solid #ebedf0;
    //   //   -webkit-transform: scaleY(0.5);
    //   //   transform: scaleY(0.5);
    //   // }
    //   .ass_cell {
    //     margin: 10px 0;
    //     .van-cell__title {
    //       font-size: 32px;
    //       font-family: Source Han Sans CN;
    //       font-weight: 500;
    //       line-height: 54px;
    //       color: #333333;
    //     }
    //   }
    // }

    .zb_group {
      // display: flex;
      border-left: 6px solid #2b8df0;
      margin: 30px 0;
      .van-cell-group {
        .top_cell {
          .van-cell__title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          .van-cell__value {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .btm_cell {
          .van-cell__title {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .van-cell__label {
            text-align: right;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-score {
              font-size: 48px;
              font-family: DIN;
              font-weight: bold;
              color: #2b8df0;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .f_left {
      width: 40%;
      text-align: center;
      border-right: 2px solid #cccccc;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #f53838;
    }
    .f_right {
      width: 40%;
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #2b8df0;
    }
  }
}
</style>
